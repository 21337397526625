.container {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.thead {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.theadTd {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.theadTdSphere {
  text-align: left;
  width: 400px;
}

.td {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.tdNumber {
  width: 50px;
}

.tdSphere {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.tr {
  border-bottom: 1px solid #ececec;
}

.tr:last-of-type {
  border-bottom: none;
}

.tr:first-child .td {
  padding-top: 35px;
}

.showMore {
  padding-top: 15px;
  text-align: center;
}

.showMore span {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ec412c;
}

.showMore span:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 1px solid #ec412c;
  border-bottom: 1px solid #ec412c;
  transform: rotate(45deg);
  margin-left: 8px;
  vertical-align: middle;
  margin-top: -4px;
}