.circle {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 100%;
}

.title {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0.14px;
  margin-right: auto;
}

.data {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  color: #676a6c;
}