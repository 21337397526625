@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #f3f3f4;
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  height: 100%;
  font-size: 14px;
  line-height: 1.43;
}

button {
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  font-size: 14px;
  line-height: 1.43;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.apexcharts-datalabels {
  display: none;
}

.apexcharts-data-labels {
  display: none;
}