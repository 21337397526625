@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #f3f3f4;
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  height: 100%;
  font-size: 14px;
  line-height: 1.43;
}

button {
  font-family: 'Open Sans', sans-serif;
  color: #676a6c;
  font-size: 14px;
  line-height: 1.43;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.apexcharts-datalabels {
  display: none;
}

.apexcharts-data-labels {
  display: none;
}
.downloadIssuesList_container__3y4nk {
  background-color: white;
  padding: 35px 35px 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.downloadIssuesList_title__2c5A8 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

.downloadIssuesList_filters__1HXW5 {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.downloadIssuesList_filters__1HXW5:last-child {
  margin-bottom: 0;
}

.downloadIssuesList_filter__3UMbr {
  max-width: 400px;
  width: 100%;
}

.downloadIssuesList_filterSecondary__1Fwy2 {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.downloadIssuesList_filterSecondary__1Fwy2:last-child {
  margin-right: 40px;
}

.downloadIssuesList_filterDates__uOM84 {
  max-width: 255px;
  width: 100%;
  margin-right: 30px;
}

.downloadIssuesList_filterDates__uOM84 .DateInput {
  width: 100px;
}

.downloadIssuesList_filterDates__uOM84 .DateInput_input {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.downloadIssuesList_filterDates__uOM84 .DateInput_input::-webkit-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.downloadIssuesList_filterDates__uOM84 .DateInput_input:-ms-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.downloadIssuesList_filterDates__uOM84 .DateInput_input::-ms-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.downloadIssuesList_filterDates__uOM84 .DateInput_input::placeholder {
  color: #aaaaaa;
  text-align: center;
}

.downloadIssuesList_filterDates__uOM84 .DateRangePickerInput_arrow_svg {
  fill: #aaaaaa;
}

.downloadIssuesList_filterDates__uOM84 .DateRangePickerInput__withBorder {
  border: 1px solid #e8eaec;
}

.downloadIssuesList_button__WamsO {
  min-width: 160px;
  height: 33px;
  border: none;
  border-radius: 6px;
  background-color: rgba(219, 53, 42, 0.9);
  color: white;
  cursor: pointer;
}

.downloadIssuesList_button__WamsO:hover {
  background-color: rgba(219, 53, 42, 1);
}

.downloadIssuesList_button__WamsO:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.downloadIssuesList_button_icon__1XT45 {
  width: 11px;
  height: 11px;
  margin-right: 9px;
}

.downloadIssuesList_oneContainer__3vag6 {
  padding: 30px 0 50px;
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
}

.downloadIssuesList_oneContainer__3vag6:first-of-type {
  padding-top: 0;
}

.downloadIssuesList_oneContainer__3vag6:last-of-type {
  border-bottom: none;
}
.css-loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.loading-container {
  position: relative;
}

.loading-container .css-loader {
  display: block;
}

.loading-container > *:not(.css-loader) {
  opacity: 0.4;
  pointer-events: none;
}

.dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #ec412c transparent #151515 transparent;
  -webkit-animation: dual-ring 1.2s linear infinite;
          animation: dual-ring 1.2s linear infinite;
}

@-webkit-keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.executorsRating_container__1qNSt {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.executorsRating_title__2bJbk {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 20px;
}

.executorsRating_table__eveaB {
  border-collapse: collapse;
  width: 100%;
}

.executorsRating_thead__1aTPE {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.executorsRating_theadTd__Eu_0g {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.executorsRating_theadTdSphere__12lR4 {
  text-align: left;
  width: 400px;
}

.executorsRating_td__8bsSe {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.executorsRating_tdNumber__aCmqq {
  width: 50px;
}

.executorsRating_tdSphere__5fTWb {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.executorsRating_tr__3O9mV {
  border-bottom: 1px solid #ececec;
}

.executorsRating_tr__3O9mV:first-child .executorsRating_td__8bsSe {
  padding-top: 35px;
}
.categoriesRating_container__3LCWJ {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.categoriesRating_title__1hXuB {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 20px;
}

.categoriesRating_table__1JTUG {
  border-collapse: collapse;
  width: 100%;
}

.categoriesRating_thead__2BLzD {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.categoriesRating_theadTd__2W2n4 {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.categoriesRating_theadTdSphere__28hBU {
  text-align: left;
  width: 400px;
}

.categoriesRating_td__2IJoQ {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.categoriesRating_tdNumber__zyWer {
  width: 50px;
}

.categoriesRating_tdSphere__3XGFR {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.categoriesRating_tr__33tA9 {
  border-bottom: 1px solid #ececec;
}

.categoriesRating_tr__33tA9:last-of-type {
  border-bottom: none;
}

.categoriesRating_tr__33tA9:first-child .categoriesRating_td__2IJoQ {
  padding-top: 35px;
}

.categoriesRating_showMore__2Xya8 {
  padding-top: 15px;
  text-align: center;
}

.categoriesRating_showMore__2Xya8 span {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ec412c;
}

.categoriesRating_showMore__2Xya8 span:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 1px solid #ec412c;
  border-bottom: 1px solid #ec412c;
  transform: rotate(45deg);
  margin-left: 8px;
  vertical-align: middle;
  margin-top: -4px;
}
.regionsRating_container__3ksYN {
  background-color: white;
  padding: 35px 25px 40px 45px;
  margin-bottom: 25px;
}

.regionsRating_title__2OXJO {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 20px;
}

.regionsRating_table__1Qmwj {
  border-collapse: collapse;
  width: 100%;
}

.regionsRating_thead__4q-H5 {
  border-bottom: 2px solid rgba(185, 185, 185, 0.3);
  font-weight: 600;
  letter-spacing: 0.14px;
}

.regionsRating_theadTd__XRSKd {
  width: 205px;
  padding: 20px 0 20px 15px;
  text-align: center;
}

.regionsRating_theadTdSphere__HmTfe {
  text-align: left;
  width: 400px;
}

.regionsRating_td__ngrA7 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  padding: 25px 0 25px 20px;
  text-align: center;
}

.regionsRating_tdNumber__T2NiJ {
  width: 50px;
}

.regionsRating_tdSphere__OSz1W {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.regionsRating_tr__2x8HQ {
  border-bottom: 1px solid #ececec;
}

.regionsRating_tr__2x8HQ:last-of-type {
  border-bottom: none;
}

.regionsRating_tr__2x8HQ:first-child .regionsRating_td__ngrA7 {
  padding-top: 35px;
}

.regionsRating_showMore__3tcyM {
  padding-top: 15px;
  text-align: center;
}

.regionsRating_showMore__3tcyM span {
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ec412c;
}

.regionsRating_showMore__3tcyM span:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 1px solid #ec412c;
  border-bottom: 1px solid #ec412c;
  transform: rotate(45deg);
  margin-left: 8px;
  vertical-align: middle;
  margin-top: -4px;
}
.issuesByStatus_container__3c98u {
  background-color: white;
  padding: 35px 100px 35px 35px;
  margin-bottom: 25px;
}

.issuesByStatus_title__2PGBy {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

table {
  width: 100%;
}

th,
td {
  text-align: left;
}

th {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: #676a6c;
  padding-bottom: 15px;
  border-bottom: solid 1px #ececec;
}

td {
  padding: 10px 0;
}

.issuesByStatus_indicators__fnE0e {
  display: flex;
  justify-content: space-between;
  padding-left: 65px;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.issuesByStatus_chart__YabsO {
  max-width: 250px;
  max-height: 250px;
  margin-right: 100px;
  margin-left: 50px;
}

.issuesByStatus_chartContainer__2MHN1 {
  display: flex;
  align-items: center;
}

.issuesByStatus_legend__2jLNK {
  list-style: none;
  flex: 1 1;
}

.issuesByStatus_legend__item__3kSwb {
  margin-bottom: 20px;
}

.issuesByStatus_legend__item__3kSwb:last-child {
  margin-bottom: 0;
}
.legendItem_circle__3DZc8 {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 100%;
}

.legendItem_title__1AdiR {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0.14px;
  margin-right: auto;
}

.legendItem_data__VBiYa {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  color: #676a6c;
}
.analytics_container__2D7J0 {
  margin-top: 25px;
}

.analytics_wrapper__3CvqI {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto;
}

.analytics_wrapperHeader__2gMsK {
  max-width: 1220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin: 0 auto;
}

.analytics_header__gqG0e {
  background: #fff;
  margin-bottom: 25px;
}

.analytics_headerText__F3TUG {
  font-weight: bold;
  padding: 20px 0;
}

.analytics_title__1-Bsm {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  margin-bottom: 10px;
}

.analytics_filterDates__3d3_v .DateInput {
  width: 135px;
}

.analytics_filterDates__3d3_v .DateInput_input {
  font-size: 14px;
  line-height: 1.43;
  padding: 6px 11px 5px;
}

.analytics_filterDates__3d3_v .DateInput_input::-webkit-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.analytics_filterDates__3d3_v .DateInput_input:-ms-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.analytics_filterDates__3d3_v .DateInput_input::-ms-input-placeholder {
  color: #aaaaaa;
  text-align: center;
}

.analytics_filterDates__3d3_v .DateInput_input::placeholder {
  color: #aaaaaa;
  text-align: center;
}

.analytics_filterDates__3d3_v .DateRangePickerInput_arrow_svg {
  fill: #aaaaaa;
}

.analytics_filterDates__3d3_v .DateRangePickerInput__withBorder {
  border: 1px solid #e8eaec;
}

.analytics_buttonWrapper__2mwXz {
  max-width: 1220px;
  padding: 0 25px;
  margin: 0 auto 25px;
}

.analytics_button__3zcQI {
  display: flex;
  align-items: center;
  position: relative;
  color: #ec412c;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding-left: 20px;
}

.analytics_button__3zcQI::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 13px;
  left: 0;
  display: inline-block;
  transform: rotate(-90deg);
  background: url(/static/media/arrow-red.196509ee.svg) no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.analytics_icon__2nPVE {
  height: 8px;
  width: 13.5px;
  transform: rotate(-90deg);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
