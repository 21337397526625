.container {
  background-color: white;
  padding: 35px 100px 35px 35px;
  margin-bottom: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  margin-bottom: 40px;
}

table {
  width: 100%;
}

th,
td {
  text-align: left;
}

th {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: #676a6c;
  padding-bottom: 15px;
  border-bottom: solid 1px #ececec;
}

td {
  padding: 10px 0;
}

.indicators {
  display: flex;
  justify-content: space-between;
  padding-left: 65px;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.chart {
  max-width: 250px;
  max-height: 250px;
  margin-right: 100px;
  margin-left: 50px;
}

.chartContainer {
  display: flex;
  align-items: center;
}

.legend {
  list-style: none;
  flex: 1;
}

.legend__item {
  margin-bottom: 20px;
}

.legend__item:last-child {
  margin-bottom: 0;
}