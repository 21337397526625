.css-loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.loading-container {
  position: relative;
}

.loading-container .css-loader {
  display: block;
}

.loading-container > *:not(.css-loader) {
  opacity: 0.4;
  pointer-events: none;
}

.dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #ec412c transparent #151515 transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
